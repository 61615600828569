import React from "react";
import Layout from "../components/layout";
import {IoCheckmarkSharp} from "react-icons/io5";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "gatsby";
import SEO from "../components/seo";

const Pricing = () => {
    const CheckmarkListItem = (props: any) => {
        return (
            <li>
                <IoCheckmarkSharp className={'text-orange'} style={{fontSize: '22px', marginBottom: '2px'}}/>{' '}
                {props.text}
            </li>
        )
    }

    return (
        <Layout>
            <SEO title={'Pricing'} />


            <section className="py-5 bg-dark">
                <Container>
                    <Row className={'justify-content-center'}>
                        <Col lg={6}>
                            <div className="text-center">
                                <h3 className={'text-light'}>Choose Simple <span className="text-orange">Pricing</span>
                                </h3>
                                <p className="text-muted mt-2">
                                    We have a volume based pricing model where you pay for what you use.
                                    Contact us to schedule a free demo or see what prices will apply for your business.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5 pt-3 justify-content-center">
                        <Col xl={4} lg={5} md={6} sm={8} xs={10}>
                            <Card className="card-pricing bg-dark-gray">
                                <Card.Body>
                                    <p className="fw-bold text-uppercase text-center m-0 text-orange">Pay for what you use</p>
                                    <h2 className="card-pricing-price text-center">
                                        <span>As low as 0.1 NOK / Job</span>
                                    </h2>

                                    <ul className="card-pricing-features">
                                        <CheckmarkListItem text={'Advanced route optimization'}/>
                                        <CheckmarkListItem text={'Plan as often as you like'}/>
                                        <CheckmarkListItem text={'Get optimized routes in minutes'}/>
                                        <CheckmarkListItem text={'API and Admin Panel'}/>
                                        <CheckmarkListItem text={'Wide variety of constraints'}/>
                                        <CheckmarkListItem text={'Email and phone support'}/>
                                    </ul>
                                    <Button as={Link} to={'/contact'} className="mt-4 mb-2 btn-orange shadow-none">
                                        Choose Plan
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>


                    </Row>

                </Container>
            </section>

        </Layout>
    )
}

export default Pricing